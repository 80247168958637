import React, { useCallback, useState } from 'react'
import { SettlementListStyles } from '@/app/stylesheets'
import { SettlementListComposition } from '@/app/stylesheets/SettlementList'
import {
  SettlementItem,
  Placeholder,
  PageSection,
  Button,
  View,
  RequireSignatureModal,
  UploadSettlement,
  SignaturesRequestedModal,
} from '@/components'
import { APIClient } from '@/services'
import { Dispute, DisputeSettlement } from '@/types'
import { ComponentVariants, StylesOf, useBooleanToggle, useDefaultComponentStyle } from '@codeleap/common'
import { DisputeUtils, Navigation, useMediaQueryDown, useSettlements } from '@/utils'
import { variantProvider } from '@/app'
import { EditContext } from './EditContextModal'

type SettlementListProps = ComponentVariants<typeof SettlementListStyles> & {
  styles?: StylesOf<SettlementListComposition>
  style?: StyleSheet
  dispute?: Dispute
}

const Void = () => <View css={_styles.void} />

export function SettlementList({ dispute, ...props }: SettlementListProps) {
  const { variants, styles, responsiveVariants } = props

  const isMobile = useMediaQueryDown('small')
  const isMid = useMediaQueryDown('large')

  const [visibleRequireSignature, toggleRequireSignature] = useBooleanToggle(false)
  const [visibleUploadSettlement, toggleUploadSettlement] = useBooleanToggle(false)
  const [visibleRequestedSignatures, toggleRequestedSignatures] = useBooleanToggle(false)
  const [visibleEditContext, toggleEditContext] = useBooleanToggle(false)

  const [settlementToEdit, setSettlementToEdit] = useState<DisputeSettlement>({} as DisputeSettlement)

  const { profile } = APIClient.Session.useSession()
  const { settlements, settlementToSign } = APIClient.Settlements.useSettlements(dispute?.id)
  const {
    allIsSigned,
    allowSignatures,
    showSignButton,

    handleDelete,
    handleDownload,
    handleMarkAsReady,

    DownloadFinalVersionButton,
    NavigateToSignButton,
  } = useSettlements({ dispute })

  const isMediatorFromDispute = DisputeUtils.getParticipantRoleName(dispute, profile) === 'Mediator'

  const variantStyles = useDefaultComponentStyle<'u:SettlementList', typeof SettlementListStyles>('u:SettlementList', {
    variants,
    styles,
    responsiveVariants,
  })

  const ManageSiganturesButton = useCallback(
    () => (
      <Button
        text={!!settlementToSign ? 'Signature Status' : 'Request Signatures'}
        icon={!!settlementToSign ? 'clipboard-check' : 'pen-tool'}
        debugName='set settlement to sign button'
        variants={['primary:outline', isMid && 'marginBottom:2', 'small']}
        css={_styles.button}
        onPress={!!settlementToSign ? toggleRequestedSignatures : toggleRequireSignature}
      />
    ),
    [isMid, settlementToSign],
  )

  const RenderItem = useCallback(({ settlement, index }) => {
    const itemPosition = {
      isLast: index === settlements.items?.length - 1,
      isFirst: index === 0,
      isOnly: settlements.items?.length === 1,
    }

    const onEditContext = () => {
      setSettlementToEdit(settlement)
      setTimeout(() => toggleEditContext())
    }

    return (
      <SettlementItem
        {...itemPosition}
        settlement={settlement}
        onDelete={() => handleDelete(settlement)}
        onMarkAsReady={() => handleMarkAsReady(settlement)}
        onDownload={() => handleDownload(settlement)}
        onEditContext={onEditContext}
      />
    )
  }, [])

  const RightOptions = useCallback(() => {
    const variants = ['primary:outline', 'small', isMobile && 'pageSectionMobile']

    if (!isMediatorFromDispute) {
      return (
        <>
          {showSignButton ? <NavigateToSignButton variants={variants} /> : <Void />}
          {allIsSigned && (isMobile ? <Void /> : <DownloadFinalVersionButton variants={variants} />)}
        </>
      )
    }

    if (isMediatorFromDispute) {
      return (
        <View variants={['gap:2']}>
          {!isMid && allowSignatures ? <ManageSiganturesButton /> : null}
          <Button
            variants={variants as any}
            text='Upload'
            icon='upload'
            debugName='upload settlement button'
            onPress={toggleUploadSettlement}
          />
        </View>
      )
    }
  }, [isMid, isMobile])

  return (
    <>
      <PageSection
        title='Settlements'
        onBack={() => Navigation.goToDispute({ id: dispute.id })}
        headerRightComponent={<RightOptions />}
      >
        <View css={variantStyles.wrapper}>
          {!!settlements.items?.length ? (
            <>
              {isMobile && allIsSigned && !isMediatorFromDispute && (
                <DownloadFinalVersionButton
                  variants={['primary:outline', 'marginBottom:2', 'small']}
                  css={_styles.button}
                />
              )}
              {isMid && isMediatorFromDispute && <ManageSiganturesButton />}
              {settlements.items?.map((settlement, i) => (
                <RenderItem key={`settlement-item-${settlement?.id ?? i}`} settlement={settlement} index={i} />
              ))}
            </>
          ) : (
            <Placeholder
              variants={['compact']}
              icon={'file-text' as any}
              text='All settlement documents will be placed here'
            />
          )}
        </View>
      </PageSection>
      <EditContext visible={visibleEditContext} toggle={toggleEditContext} settlement={settlementToEdit} />
      <UploadSettlement disputeId={dispute.id} visible={visibleUploadSettlement} toggle={toggleUploadSettlement} />
      <SignaturesRequestedModal
        dispute={dispute}
        settlement={settlements.items?.[0]}
        visible={visibleRequestedSignatures}
        toggle={toggleRequestedSignatures}
        onSuccess={settlements.refresh}
      />
      <RequireSignatureModal
        disputeId={dispute.id}
        settlementId={settlements.items?.[0]?.id}
        visible={visibleRequireSignature}
        toggle={toggleRequireSignature}
      />
    </>
  )
}

const _styles = variantProvider.createComponentStyle(
  theme => ({
    button: {
      border: 'none',
      width: 'fit-content',
      ...theme.spacing.marginHorizontal('auto'),
    },
    void: {
      width: theme.spacing.value(4),
    },
  }),
  true,
)
